import React, { useRef } from 'react';

import SEO from '../components/seo';
import { Link, graphql } from 'gatsby';
import { Layout } from '../layout/Layout';
import {
  BannerWithBackground as Banner,
  Description,
  Details,
  ContactBanner,
  SectionWithImage,
} from '../components/common';
import Benefits from '../components/modules/evaluaciones/Benefits';
import sanitizeHtml from 'sanitize-html';
import { useQueryParam } from 'gatsby-query-params';
import useDragScroll from 'use-drag-scroll';
import { clamp } from '../utils/helpers';

export const query = graphql`
  query EvaluacionesEspecializadasQuery {
    site {
      siteMetadata {
        cmsUrl
      }
    }
    directus {
      specialized_evaluations {
        content(sort: "sort") {
          id
          image {
            id
          }
          bottom_banner_image {
            id
          }
          translations {
            title
            short_title
            description
            details
            details_cards
            details_note
            table_headers
            scores
            benefits
            benefits_button
            bottom_banner_text
            bottom_banner_button
            languages_code {
              code
            }
          }
        }
      }
    }
  }
`;

const EvaluacionesEspecializadas = ({ data, pageContext }) => {
  const { banner_image_mobile, banner_image } = pageContext.data;

  const { banner_title, banner_text, contact_banner_text, contact_banner_button } = pageContext.translation;

  const content = data.directus.specialized_evaluations.content
    .map(element => ({
      image: element.image,
      bottom_banner_image: element.bottom_banner_image,
      spanishTitle: element.translations.filter(t => t.languages_code.code === 'es-ES')[0].title,
      ...element.translations.filter(t => t.languages_code.code === pageContext.languageCode)[0],
    }))
    .filter(e => e.title && e.description);

  let contentIndex = useQueryParam('tab', 1);
  contentIndex = parseInt(contentIndex) || 1;
  contentIndex = clamp(contentIndex, 1, content.length);
  const pageContent = content[contentIndex - 1];
  const cmsURL = data.site.siteMetadata.cmsUrl;

  // Scroll on tabs
  const selectSliderRef = useRef();
  useDragScroll({
    sliderRef: selectSliderRef,
  });

  return (
    <Layout pageId="evaluaciones" currentLanguage={pageContext.languageCode}>
      <Banner
        title={banner_title}
        description={banner_text}
        desktopImage={`${cmsURL}/assets/${banner_image.id}`}
        mobileImage={`${cmsURL}/assets/${banner_image_mobile.id}`}
      />

      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div ref={selectSliderRef} className="select-slider no-scrollbar">
              {content.map((pageInfo, index) => {
                return (
                  <Link
                    key={pageInfo.title}
                    className={`btn ${parseInt(contentIndex) === index + 1 ? 'active' : ''}`}
                    to={`?tab=${index + 1}`}
                  >
                    {pageInfo.short_title || pageInfo.title}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Description
        heading={pageContent.title}
        body={pageContent.description}
        image={pageContent.image ? `${cmsURL}/assets/${pageContent.image.id}` : null}
      />
      <Details
        content={pageContent.details}
        cards={pageContent.details_cards}
        tableHeaders={pageContent.table_headers}
        tableContent={pageContent.scores}
        note={pageContent.details_note}
        currentLanguage={pageContext.languageCode}
      />
      <Benefits
        content={pageContent.benefits}
        link={`${pageContext.basePath}/contact`}
        linkState={{ subject: pageContent.spanishTitle }}
        buttonText={pageContent.benefits_button}
      />

      {pageContent.bottom_banner_text && pageContent.bottom_banner_button ? (
        <SectionWithImage
          className="express"
          image={pageContent.bottom_banner_image ? `${cmsURL}/assets/${pageContent.bottom_banner_image.id}` : null}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(pageContent.bottom_banner_text),
            }}
          />
          <Link className="btn primary-btn express__link" to={`${pageContext.basePath}/contact`}>
            {pageContent.bottom_banner_button}
          </Link>
        </SectionWithImage>
      ) : (
        <ContactBanner
          title={contact_banner_text}
          buttonText={contact_banner_button}
          to={`${pageContext.basePath}/contact`}
        />
      )}
    </Layout>
  );
};

export default EvaluacionesEspecializadas;

export const Head = () => <SEO title="Specialized Assessments - MicroRate" />;
